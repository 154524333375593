.soc-floating-input, .soc-floating-select {
	font-size: 14px;
	padding: 0;
}

.soc-floating-input {
	padding: 0 8px;
	margin-bottom: 14px;
}
.soc-floating-input input{
	font-size: 14px;
}

/*.soc-floating-select label {
	left: 8px;
}*/

.soc-floating-input.form-floating label {
	left: 6px;
}
.soc-floating-select.form-floating label,
.soc-floating-input.form-floating label {
	top: -3px;
}

.soc-floating-input.form-floating input.form-control,
.soc-floating-select.form-floating .form-select {
	padding-top: 20px;
	padding-bottom: 5px;
	padding-left: 11px;
	height: 46px;
}


.soc-floating-input.form-floating .form-select {
	padding-top: 20px;
	padding-bottom: 5px;
	padding-left: 11px;
	height: 46px;
}