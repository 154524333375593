.select-list .select-list-row {
	height: 60px;
	border: 1px solid var(--border-color);
}

.select-list .select-list-row:not(:last-child) {
	border-bottom: none;
}

.select-list .select-list-row button, 
.select-list .select-list-row a {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 10px 20px;
	color: var(--text-black);
	text-decoration: none;
	border: none;
	background-color: transparent;
}

.select-list .select-list-row button:hover, 
.select-list .select-list-row a:hover {
	/*color: var(--text-black);
	background-color: var(--brand-pink);*/
	/*color: var(--text-white);*/
	color: var(--brand-pink);
	background-color: var(--brand-green);
	border: none;
}