.ad-builder-sidebar {
	height: 100%;
	width: var(--sidebar-width);
/*	background-color: var(--brand-pink);*/
	background-color: var(--theme-mid-grey);
	padding-top: 20px;
}

.ad-builder-sidebar .input-wrapper {
	max-width: 100%;
	margin-bottom: 14px;
}