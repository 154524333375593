.no-social-accounts {
	width: 100%;
	height: 100%;
	/*display: flex;*/
	/*align-items: center;*/
	/*justify-content: center;*/
	text-align: center;	
	margin-top: calc(100vh * .15);
}

.no-social-accounts h1 {
	margin-bottom: 40px;
}
