/* remove bootstrap borders when clicking buttons */
/*.btn:focus, .btn:active:focus, .form-control:focus  {
	box-shadow: none;
}
*/
@font-face {
  font-family: 'Galaxie Polaris';
  ascent-override: 95%;
  src: local('Galaxie Polaris'), url(./fonts/Galaxie Polaris Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Galaxie Polaris';
  font-weight: 700;
  ascent-override: 95%;
  src: local('Galaxie Polaris'), url(./fonts/Galaxie Polaris Bold.otf) format('opentype');
}

body {
	/*background-color: var(--theme-light);*/
/*	background-color: var(--theme-light-grey);*/
	background-color: #fff;
	color: var(--text-black);
	font-family: "Galaxie Polaris", sans-serif;
	font-size: 14px;
	/*line-height: .9px;*/
}

.card {
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
}

.card .card-header {
	background-color: #fff;
	/*background-color: var(--border-color);*/
	/*border-bottom: 1px solid var(--border-color);*/
	border-bottom: none;
	font-weight: bold;

	text-decoration: underline;
	text-decoration-color: var(--brand-pink);
	text-decoration-thickness: 4px;
	font-size: 24px;
}
.card .card-header:first-child {
	border-radius: var(--border-radius) var(--border-radius) 0 0;
}

input {
	border-radius: 10px 0 10px 0;
}

h1, h2, h3, h4, h5, h6 {
	text-decoration: underline;
	text-decoration-color: var(--brand-pink);
	font-weight: bold;
}

h1 {
	text-decoration-thickness: 5px;
}

h2, h3, h4, h5, h6 {
	text-decoration-thickness: 4px;
}

.has-border {
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
}

.btn, .btn-sm, .btn-lg, button {
	border-radius: 0;
}

.modal .modal-header button.close:focus,
.btn:focus {
	/*border-color: #86b7fe;*/
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}

.team-circle {
	border-radius: 100%;
	width: 60px;
	height: 60px;
	display: inline-block;
	background-color: aliceblue;
}

.form-page {
	padding: 26px 40px;
	max-width: 800px;
	margin-bottom: 80px;
}

.placeholder {
	cursor: default;
}