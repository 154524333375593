.team-layout-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
}

.team-layout-wrapper .team-sidebar {
	flex-direction: column;
	overflow-y: auto;
}

.team-layout-wrapper .page-container {
/*	width: calc(100% - var(--sidebar-width));*/
	width: 100%;
	overflow-y: auto;
}