:root {
	--ad-builder-header-height: 48px;
}

.ad-builder-page {
	height: 100%;
	overflow: hidden;
	display: flex;
}

.ad-builder-page .create-ad-from-post {
	display: inline-block;
	margin: 10px;
	border: 1px solid var(--border-color);
}

.ad-builder-page .post-list {
/*	margin-top: var(--ad-builder-header-height);*/
/*	height: calc(100% - var(--ad-builder-header-height));*/
	height: 100%;
	width: calc(100% - var(--sidebar-width));
	overflow-y: auto;
	padding: 10px;
	padding-bottom: 80px;
}

.ad-builder-page .post-wrapper {
	margin: 5px;
	padding: 8px;
/*	border: 1px solid var(--border-color);*/
/*	border-radius: var(--border-radius);*/
	background-color: var(--theme-mid-grey);
	display: inline-block;
}