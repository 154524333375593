.spinner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.spinner-container .spinner-wrapper {
	text-align: center;
	color: var(--brand-primary);
}

.spinner-container .spinner-border {
	font-size: 16px;
}

.spinner-container .spinner-border.spinner-sm {
	font-size: 12px;
	width: 16px;
	height: 16px;
}

.spinner-overlay .spinner-text {
	font-size: 24px;
	margin-top: 20px;
}

.spinner-overlay {
	width: 100%;
	height: 100%;
	/*position: fixed;*/
	position: absolute;
	background: rgba(255, 255, 255, 0.9);
	/*background-color: var(--brand-pink);*/
	z-index: 1000;
	top: 0;
	left: 0;
}

.spinner-overlay .spinner-container .spinner-wrapper {
	color: var(--brand-green);
}

.spinner-overlay .spinner-border {
	width: 45px;
	height: 45px;
	font-size: 22px;
}

.spinner-overlay .spinner-container {
	align-items: baseline;
	/*margin-top: calc(100vh * .33);*/
	/*margin-top: calc(100% * .33);*/
	padding-top: 15%;
}
