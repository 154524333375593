.account-dropdown .dropdown-toggle {
	color: white;
/*	color: var(--brand-green);*/
/*	color: var(--brand-pink);*/
	font-size: 24px;
}

.account-dropdown .show .dropdown-toggle,
.account-dropdown .dropdown-toggle:hover {
	/*color: var(--brand-primary);*/
	/*color: var(--text-white);*/
/*	color: var(--text-black);*/
	color: var(--brand-pink);
}

.account-dropdown .dropdown-toggle::after {
	display: none;
}


.account-dropdown .dropdown-menu a {
	color: var(--text-black);
	width: 100%;
	padding: 8px 10px;
}

.account-dropdown .dropdown-menu {
	font-size: 14px;
	margin-top: -6px;
}

.account-dropdown .dropdown-menu a:hover {
	/*color: var(--text-white);*/
	/*color: var(--theme-dark);*/
	/*color: var(--text-black);*/
	color: var(--text-white);
	/*background-color: var(--brand-primary);*/
	background-color: var(--brand-green);
	/*color: var(--brand-primary);
	background-color: var(--theme-dark);*/
}