.missing-media {
	width: var(--social-post-media-width);
	/*height: 250px;*/
	background-color: #ddd;
	text-align: center;
	padding-top: 40%;
}

.missing-media.is-square {
	width: var(--social-post-media-width);
	height: var(--social-post-media-width);
}