:root {
	--brand-pink: #e9c3b8;
	--brand-green: #2c4953;
	/*--brand-light-green: #76c6ca;*/
	--brand-light-green: #8edadc;

	--brand-primary: var(--brand-pink);
	--brand-secondary: var(--brand-light-green);

	--theme-dark: var(--brand-green);
	--theme-light: #fff;
	--text-black: #224a54;
	--text-white: #fff;
	--theme-light-grey: #f9f9f9;
	--theme-mid-grey: #ebebeb;

	--border-color: #ced4da;
	--border-radius: 0.5rem;
	--sidebar-width: 260px;
	--header-height: 64px;



	--theme-blue: #4299e1;
	--theme-blue-light: #63b3ed;
	--theme-blue-dark: #3182ce;
	--theme-blue-darker: #2B6CB0;

	--theme-cyan: #76E4F7;
	--theme-cyan-light: #0BC5EA;
	--theme-cyan-dark: #00B5D8;
	--theme-cyan-darker: #00A3C4;

	--theme-green: #48BB78;
	--theme-green-light: #68D391;
	--theme-green-dark: #38A169;
	--theme-green-darker: #2F855A;

	--theme-red: #F56565;
	--theme-red-light: #FC8181;
	--theme-red-dark: #E53E3E;
	--theme-red-darker: #C53030;

	--theme-yellow: #ECC94B;
	--theme-yellow-light: #F6E05E;
	--theme-yellow-dark: #D69E2E;
	--theme-yellow-darker: #B7791F;

	--theme-orange: #ED8936;
	--theme-orange-light: #F6AD55;
	--theme-orange-dark: #DD6B20;
	--theme-orange-darker: #C05621;


	--bs-primary: var(--theme-blue);
	--bs-success: var(--theme-green);
	--bs-info: var(--theme-cyan);
	--bs-warning: var(--theme-orange);
	--bs-danger: var(--theme-red);

	--bs-primary-dark: var(--theme-blue-dark);
	--bs-success-dark: var(--theme-green-dark);
	--bs-info-dark: var(--theme-cyan-dark);
	--bs-warning-dark: var(--theme-orange-dark);
	--bs-danger-dark: var(--theme-red-dark);

	--bs-primary-darker: var(--theme-blue-darker);
	--bs-success-darker: var(--theme-green-darker);
	--bs-info-darker: var(--theme-cyan-darker);
	--bs-warning-darker: var(--theme-orange-darker);
	--bs-danger-darker: var(--theme-red-darker);

	--bs-primary-light: var(--theme-blue-light);
	--bs-success-light: var(--theme-green-light);
	--bs-info-light: var(--theme-cyan-light);
	--bs-warning-light: var(--theme-orange-light);
	--bs-danger-light: var(--theme-red-light);
}

/*
primary
success
info
warning
danger
*/

.bg-primary {
	background-color: var(--bs-primary) !important;
}
.bg-success {
	background-color: var(--bs-success) !important;
}
.bg-info {
	background-color: var(--bs-info) !important;
}
.bg-warning {
	background-color: var(--bs-warning) !important;
}
.bg-danger {
	background-color: var(--bs-danger) !important;
}

.btn {
	border: none;
}

.btn-primary {
	background-color: var(--bs-primary);
}
.btn-success {
	background-color: var(--bs-success);
}
.btn-info {
	background-color: var(--bs-info);
}
.btn-warning {
	background-color: var(--bs-warning);
}
.btn-danger {
	background-color: var(--bs-danger);
}


.btn.btn-primary:hover,
.btn.btn-primary:focus {
	background-color: var(--bs-primary-dark) !important;
}
.btn.btn-success:hover,
.btn.btn-success:focus {
	background-color: var(--bs-success-dark) !important;
}
.btn.btn-info:hover,
.btn.btn-info:focus {
	background-color: var(--bs-info-dark) !important;
}
.btn.btn-warning:hover,
.btn.btn-warning:focus {
	background-color: var(--bs-warning-dark) !important;
}
.btn.btn-danger:hover,
.btn.btn-danger:focus {
	background-color: var(--bs-danger-dark) !important;
}

.btn.btn-primary:disabled {
	background-color: var(--bs-primary-light);
}
.btn.btn-success:disabled {
	background-color: var(--bs-success-light);
}
.btn.btn-info:disabled {
	background-color: var(--bs-info-light);
}
.btn.btn-warning:disabled {
	background-color: var(--bs-warning-light);
}
.btn.btn-danger:disabled {
	background-color: var(--bs-danger-light);
}


.btn.btn-primary:active {
	background-color: var(--bs-primary-darker) !important;
}
.btn.btn-success:active {
	background-color: var(--bs-success-darker) !important;
}
.btn.btn-info:active {
	background-color: var(--bs-info-darker) !important;
}
.btn.btn-warning:active {
	background-color: var(--bs-warning-darker) !important;
}
.btn.btn-danger:active {
	background-color: var(--bs-danger-darker) !important;
}

