/*.app-header .header-nav-item {
	
}*/

.app-header .header-nav-item a {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.app-header .header-nav-item a:hover {
/*	color: var(--brand-secondary);*/
	color: var(--brand-pink);
}

.app-header .header-nav-item i {
	font-size: 20px;
/*	font-size: 24px;*/
/*	margin-right: 8px;*/
}

.app-header .header-nav-item .label {
	font-size: 10px;
}


.app-header .header-nav-item.is-active .label {
	text-decoration: underline;
	text-decoration-thickness: 3px;
	text-decoration-color: var(--brand-pink);
/*	text-decoration-color: var(--brand-secondary);*/
}

