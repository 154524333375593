.app-header {
	height: var(--header-height);
}

.app-header a,
.app-header a:hover {
	color: white;
	text-decoration: none;
}

.app-header .header-logo {
	height: 23px;
	vertical-align: baseline;
}

.app-header .container-fluid {
	padding-right: 30px;
}

.app-header.top-nav {
/*	background-color: var(--brand-pink);*/
	background-color: var(--brand-green);
	/*background-color: var(--theme-dark);*/
}

.app-header.top-nav .navbar-brand {
	width: 206px;
	margin-left: 28px;
}

.app-header .t-primary {
	/*color: var(--brand-primary);*/
	color: var(--brand-green);

}
.app-header .t-secondary {
	/*color: var(--brand-secondary);*/
	color: var(--brand-green);
}

.app-header .app-header-left,
.app-header .app-header-right {
	display: flex;
	align-items: center;
}