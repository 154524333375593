.instagram-post {

}

.instagram-post .instagram-post-caption {
	height: 70px;
	overflow-y: auto;
}

.instagram-post .instagram-media-wrapper img,
.instagram-post .instagram-media-wrapper video,
.instagram-post .instagram-media-wrapper {
	width: var(--social-post-media-width);
	height: var(--social-post-media-width);
}


.instagram-post .instagram-media-wrapper img,
.instagram-post .instagram-media-wrapper video {
	object-fit: contain;
}