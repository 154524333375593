.modal {
	display: block;
	background-color: rgb(0, 0, 0, 0.5);
}

.modal .modal-title {
	font-size: 18px;
	font-weight: bold;
}
.modal .modal-footer button {
	font-size: 14px;
}

.modal .modal-header button.close {
	border: none;
	background-color: transparent;
	font-size: 16px;
	color: rgb(33, 37, 41); /* app text color */
	border-radius: .25rem;
	width: 29px;
	height: 29px;
	padding-top: 2px;
}

.modal .modal-header button.close:hover {
	color: white;
	background-color: #5c636a;
}

.modal .modal-footer {
	padding: .75rem;
}

.modal .modal-header {
	padding: .75rem;
	border-bottom: none;
}

.modal .modal-header,
.modal .modal-footer,
.modal .modal-body {
	padding-left: 20px;
}
