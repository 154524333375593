.social-icon {
	--facebook-color: #1977f2;
	--instagram-color: #dc3065;
}

.social-icon.bi-facebook.autocolor {
	color: var(--facebook-color);
}

.social-icon.bi-instagram.autocolor {
	color: var(--instagram-color);
}