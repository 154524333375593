.app-layout {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.app-layout .app-content-container {
	width: 100%;
	/*height: calc(100% - var(--header-height));*/
	height: 100%;
	overflow: auto;
}