.toast-container {
	/*top: calc(var(--header-height) + 10px);*/
	top: 20px;
	right: 20px;
	z-index: 2000;
	position: absolute;
}

.toast-container .toast {
  animation-duration: .5s;
  animation-name: slideup;
}

.toast-header {
	border-bottom: none;
	color: var(--text-black);
}

@keyframes slideup {
  from {
    margin-top: 50px;
  }

  to {
    margin-top: 0;
  }
}


/* custom colors. todo: make these part of theme */
.toast.bg-primary .toast-header {
	background-color: #96ccf2;
}
.toast.bg-success .toast-header {
	background-color: #9ae0b7;
}
.toast.bg-info .toast-header {
	background-color: #96ccf2;
}
.toast.bg-warning .toast-header {
	background-color: #f7c890;
}
.toast.bg-danger .toast-header {
	background-color: #fbacab;
}

.toast.bg-primary .toast-body {
	background-color: #def2ff;
}
.toast.bg-success .toast-body {
	background-color: #e8fff2;
}
.toast.bg-info .toast-body {
	background-color: #def2ff;
}
.toast.bg-warning .toast-body {
	background-color: #fff1e1;
}
.toast.bg-danger .toast-body {
	background-color: #ffefef;
}