.teams-page {
	align-items: center;
}

.teams-page .card {
	max-width: 440px;
	margin: 20px auto;
}

.teams-page .new-team {
	display: flex;
	justify-content: flex-end;
	/*margin-bottom: 20px;*/
	margin-top: 20px;
}